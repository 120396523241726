var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinShow}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"组织机构"}},[(_vm.type === 'add')?_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'organizationId',
          {
            validateTrigger: ['change'],
            rules: [
              {
                required: true,
                message: '请选择组织机构',
              },
              {
                validator: _vm.orgValidator,
                message: '请检查所选部门，同一用户在同一单位下只允许设置一个部门',
              } ],
          } ]),expression:"[\n          'organizationId',\n          {\n            validateTrigger: ['change'],\n            rules: [\n              {\n                required: true,\n                message: '请选择组织机构',\n              },\n              {\n                validator: orgValidator,\n                message: '请检查所选部门，同一用户在同一单位下只允许设置一个部门',\n              },\n            ],\n          },\n        ]"}],attrs:{"treeData":_vm.organizations,"treeDefaultExpandAll":"","treeNodeFilterProp":"title","dropdownStyle":{ maxHeight: '400px', overflow: 'auto' },"placeholder":"请选择组织机构"},on:{"change":_vm.handleChooseOrgChange}}):_vm._e(),(_vm.type !== 'add')?[_vm._v(" "+_vm._s(this.formItem.organizationFullName)+" ")]:_vm._e()],2),_c('a-form-item',{attrs:{"label":"职务"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['jobId']),expression:"['jobId']"}],staticStyle:{"width":"100%"},attrs:{"showSearch":"","allowClear":"","optionFilterProp":"title","placeholder":"请选择职务"}},[(_vm.jobsFetching)?_c('a-spin',{staticStyle:{"margin-left":"150px"},attrs:{"slot":"notFoundContent","size":"small"},slot:"notFoundContent"}):_vm._e(),_vm._l((_vm.jobs),function(item){return _c('a-select-option',{key:item.key,attrs:{"value":item.key,"title":item.value}},[_vm._v(" "+_vm._s(item.value)+" ")])})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }